















import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';

@Component({
    components: {
        CustomSelect,
        StyledSearchbar,
    },
})
export default class CurrencySwitcher extends Vue {
    @Inject(UserSettingsS) userSettingsService!: UserSettingsService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(CarsServiceS) private carsService!: CarsService;

    query: string = '';

    // NOTE: Adding support v-model for this component
    @Prop()
    private value!: string;

    @Prop()
    private mainCurrency!: string;

    public isLoading = false;

    get currencyItems() {
        const { allCurrencies } = this.userSettingsService;
        const currencies = allCurrencies
            ? allCurrencies.currencies
            : {};

        const currencyList = Object
            .entries(currencies)
            .map(([_, curr]) => ({
                name: `${this.getCurrencyCode(curr.code)}`,
                value: curr.code,
            }))
            .filter(
                item => (this.query
                    ? item.name
                        .toLowerCase()
                        .includes(this.query.toLowerCase())
                    : true),
            );

        if (this.mainCurrency) {
            const index = currencyList.findIndex(item => item.value === this.mainCurrency);
            if (index > -1) {
                currencyList.unshift(currencyList.splice(index, 1)[0]);
            }
        }

        currencyList.splice(0, 0, {
            name: 'By selected POS',
            value: null as unknown as string,
        });

        return currencyList;
    }

    getCurrencyCode(code: string | undefined) {
        return `${code === this.mainCurrency ? '*' : ''} ${code}`;
    }

    get currentCurrency() {
        if (this.isVModelMode()) {
            return this.value;
        }
        return this.userService.isCarUser
            ? this.carsService.storeState.settings.displayCurrency
            : this.userSettingsService.displayCurrency;
    }

    set currentCurrency(value: string | null) {
        if (this.isVModelMode()) {
            this.$emit('input', value);
            return;
        }

        this.updateCurrency(value);
    }

    private updateCurrency(value: string | null) {
        try {
            this.isLoading = true;
            if (this.userService.isCarUser) {
                this.carsService.storeState.settings.displayCurrency = value;
            } else {
                this.userSettingsService.updateDisplayCurrency(value);
            }
        } finally {
            this.isLoading = false;
        }
    }

    updateQuery(newQuery: string) {
        this.query = newQuery;
    }

    private isVModelMode() {
        const { model } = this.$vnode.data as { [k: string]: any; };

        if (model) return true;

        return false;
    }
}
